import React, {
	useState, useRef, useEffect,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import {
	Card,
	ProgressBar,
} from '../components/UI';
import {
	Quote,
	Thanks,
	OfferOnly,
	PoweredBy,
} from '../components';
import { RenderedView, SWITCH, IF } from '../components/helpers';
import { CallProvider, useDataContext } from '../context';
import {
	filterConditionalFieldsOrViews,
	getMiddleViews,
	getOfferOnlyViews,
	trimValues,
} from '../utils';
import {
	BASE_VIEW_STATUSES,
	VIEW_TYPES,
} from '../models/dictionary';
import { useNavigate, useSetDynamicValues } from '../hooks';

const InnerForm = ({
	checkoutViewInfo,
	filteredApplication,
	flatApplication,
	includeCheckout,
	initialView,
	ion = {},
	ionValues,
	logoOverride,
	offerOnlyViewData,
	onOptIn,
	onOptOut,
	partnerId,
	quoteFields,
	resolver,
	stage,
	stepThroughViews,
	StripedCheckout,
	stripe,
	views,
	viewType,
	placesApiKey,
	theme,
}) => {
	const { offeringOptions } = useDataContext();
	const [isNavShowing, setIsNavShowing] = useState(true);
	const [offerOnlyViews, setOfferOnlyViews] = useState([]);
	const offerOnlyViewIds = offerOnlyViews.map(({ id }) => id);

	const { variables } = ion;
	// we need to keep track of current view in 'single-page' views, but don't want to trigger re-renders as users scroll.
	// so we're using useRef to store/update that value.
	const currentViewRef = useRef(initialView);
	const updateCurrentView = (id) => { currentViewRef.current = id; };
	const lastNavAction = useRef();
	const updateLastNavAction = (id) => { lastNavAction.current = id; };

	const defaultValues = { ...ionValues, 'policy::partner': partnerId }; // merge in the partnerId here.

	const {
		register: rhfRegister,
		control,
		handleSubmit,
		watch,
		getValues,
		setValue: rhfSetValue,
		getFieldState,
		trigger,
		reset,
		clearErrors,
		setError,
		formState: {
			errors,
		},
	} = useForm({
		mode: 'onTouched',
		defaultValues,
		resolver: async (values, context, options) => {
			const trimmedValues = trimValues(values);
			return resolver(trimmedValues, context, options);
		},
	});

	const setDynamicValues = useSetDynamicValues({
		getValues,
		getFieldState,
		setValue: rhfSetValue, // useSetDynamicValues needs the raw setValues, otherwise we'll have an infinite loop.
		variables,
		flatApplication,
	});

	const _displayedViews = filterConditionalFieldsOrViews(stepThroughViews, getValues(), variables, offeringOptions);
	const _displayedFields = filterConditionalFieldsOrViews(flatApplication, getValues(), variables, offeringOptions).map(({ id }) => id);

	const [displayedViews, setDisplayedViews] = useState(_displayedViews);
	const [displayedFields, setDisplayedFields] = useState(_displayedFields);

	const updateHiddenElements = () => {
		const formValues = getValues();
		const newViews = filterConditionalFieldsOrViews(stepThroughViews, formValues, ion.variables, offeringOptions);
		const newFields = filterConditionalFieldsOrViews(flatApplication, formValues, variables, offeringOptions).map(({ id }) => id);
		const serializeArr = (arr) => arr.map(({ id }) => id).join(',');
		const serializedViews = serializeArr(displayedViews);
		const serializedNew = serializeArr(newViews);
		if (serializedViews !== serializedNew) {
			setDisplayedViews(newViews);
		}
		const serializedFields = displayedFields.join(',');
		const serializedNewFields = newFields.join(',');
		if (serializedFields !== serializedNewFields) {
			setDisplayedFields(newFields);
		}
	};

	/**
		@description Updates any dynamic state values (that depend on other values), and dynamically hides/shows fields.
		Only triggers re-render if there's a diff.
	 */
	const updateAppState = (e) => {
		setDynamicValues(e);
		updateHiddenElements();
	};

	const setValue = (key, value) => {
		rhfSetValue(key, value);
		/*
			No need to pass an argument to updateAppState. Arguments are for letting updateAppState to not override an input before its isTouched state has updated. SetValue is used imperatively in this instance, so no need to pass an event object (not that there is one available anyway).
		*/
		updateAppState();
	};

	const register = (name, options = {}) => (
		rhfRegister(name, {
			...options,
			onChange: (e) => {
				if (options.onChange) {
					options.onChange(e);
				}
				const { type } = e?.target || {};
				if (['radio', 'checkbox'].includes(type)) {
					updateAppState(e);
				}
			},
			onBlur: (e) => {
				if (options.onBlur) {
					options.onBlur(e);
				}
				updateAppState(e);
			},
		})
	);

	const quoteViewData = displayedViews.find(({ id, type }) => [id, type?.toLowerCase()].includes('quote'));
	const displayedMiddleViews = getMiddleViews(displayedViews);

	const postCheckoutView = displayedViews.find(({ type }) => type?.toUpperCase() === 'POST_CHECKOUT');

	// we need to update incoming values to the RHF state if parent component updates.
	useDeepCompareEffect(() => {
		reset(defaultValues);
		if (viewType === VIEW_TYPES.OFFER_ONLY) {
			const offerViews = getOfferOnlyViews({
				stepThroughViews,
				flatApplication,
				incomingValues: defaultValues,
				offeringOptions,
			});
			setOfferOnlyViews(offerViews);
		}
	}, [ionValues]);

	// offerOnlyViews only has length if we're in offer only mode and have bad data, otherwise, it's an empty array.
	const startingView = offerOnlyViews.length ? offerOnlyViews[0].id : initialView;

	const { currentView, navigate } = useNavigate({
		getValues,
		variables,
		viewType,
		viewRef: currentViewRef,
		trigger,
		clearErrors,
		// if offer only, start the app in first view returned from getOfferOnlyViews
		initialView: startingView,
		flatApplication,
		stepThroughViews: viewType === VIEW_TYPES.OFFER_ONLY ? offerOnlyViews : stepThroughViews,
		updateLastNavAction,
		displayedFields,
	});

	useEffect(() => {
		navigate(startingView);
		// run through any dynamic field calculations on initialization
		updateAppState();
	}, [ion.id, startingView]);

	const stepOrder = [
		...displayedMiddleViews.map(({ id }) => id),
		BASE_VIEW_STATUSES.CHECKOUT,
		BASE_VIEW_STATUSES.THANKS,
	];

	const handleSuccess = (id) => {
		setValue('orderId', id);
		navigate(postCheckoutView ? postCheckoutView.id : BASE_VIEW_STATUSES.THANKS);
	};

	const baseViewProps = {
		control,
		clearErrors,
		Controller,
		displayedFields,
		errors,
		flatApplication,
		getFieldState,
		getValues,
		ionId: ion.id,
		initialView: startingView,
		lastNavAction: lastNavAction.current,
		navigate,
		placesApiKey,
		register,
		setDynamicValues,
		setValue,
		setError,
		showNav: ([VIEW_TYPES.PAGINATED, VIEW_TYPES.OFFER_ONLY].includes(viewType)) && isNavShowing,
		stage,
		status: currentView,
		theme,
		toggleNav: setIsNavShowing,
		trigger,
		updateAppState,
		updateCurrentView,
		variables,
		watch,
	};

	const quoteProps = {
		...baseViewProps,
		partnerId,
		filteredApplication,
		quoteRequirements: quoteFields,
		offering: ion.id,
	};

	const steps = displayedMiddleViews.map(({ id, type, ...props }) => (
		(type === 'QUOTE' || id === 'quote') ? (
			<Quote
				{...quoteProps}
				{...props}
				id={id}
				case={id}
				key={id}
				type={type}
				stage={stage}
			/>
		)
			: (
				<RenderedView
					{...baseViewProps}
					{...props}
					id={id}
					case={id}
					key={id}
					type={type}
				/>
			)
	));

	const checkoutStep = (
		<StripedCheckout
			{...baseViewProps}
			id={checkoutViewInfo?.id || 'checkout'}
			key={BASE_VIEW_STATUSES.CHECKOUT}
			case={BASE_VIEW_STATUSES.CHECKOUT}
			stripe={stripe}
			checkoutViewInfo={checkoutViewInfo}
			handleSuccess={handleSuccess}
			includeCheckout={includeCheckout}
			onOptIn={onOptIn}
			handleSubmit={handleSubmit}
			stage={stage}
		/>
	);

	const thanksStep = postCheckoutView ? (
		<RenderedView
			key={BASE_VIEW_STATUSES.THANKS}
			{...postCheckoutView}
			{...baseViewProps}
			case={postCheckoutView.id}
		/>
	)
		: (
			<Thanks
				key={BASE_VIEW_STATUSES.THANKS}
				case={BASE_VIEW_STATUSES.THANKS}
				orderID={getValues('orderId')}
				email={getValues('customer::email')}
			/>
		);

	const allSteps = [
		...steps,
		checkoutStep,
		thanksStep,
	];

	// TODO: Revisit always creating these elements even if used conditionally.
	const offerOnly = (
		<OfferOnly
			{...quoteProps}
			id="offer-only"
			key="offer-only-component"
			onOptIn={onOptIn}
			onOptOut={onOptOut}
			case={BASE_VIEW_STATUSES.OFFER_ONLY_QUOTE}
			viewData={offerOnlyViewData}
			quoteViewData={quoteViewData}
			shouldHideBack={offerOnlyViews.length === 0}
			stage={stage}
			control={control}
		/>
	);

	const offerOnlySteps = [
		offerOnly,
		...allSteps.filter(({ key }) => offerOnlyViewIds.includes(key)),
	];

	const containerId = ion.id.replace(/_/g, '-').toLowerCase();
	// grabbing the whole current view object for conditional rendering below based on view details.
	const currentViewObj = views.find(({ id }) => id === currentViewRef.current);

	const isCheckoutAndHasNoMilestoneProp = currentViewObj?.type === 'CHECKOUT' && !(currentViewObj?.milestone);
	const shouldShowProgressBar = !(currentViewObj?.type === 'OFFER' || isCheckoutAndHasNoMilestoneProp);

	return (
		<div id={containerId}>
			<div id="offer-container">
				<Card
					footer={(
						<div id="powered-by-buddy-container">
							<PoweredBy {...logoOverride} screen={currentView} partner={partnerId} />
						</div>
					)}
				>
					<CallProvider>
						<SWITCH match={viewType}>
							{/* eslint-disable-next-line react/no-unknown-property */}
							<div case={VIEW_TYPES.PAGINATED}>
								<div>
									<IF condition={shouldShowProgressBar}>
										<ProgressBar
											stepOrder={stepOrder}
											currentIndex={stepOrder.indexOf(currentView)}
											views={stepThroughViews}
											currentViewObj={currentViewObj}
											getValues={getValues}
											variables={variables}
										/>
									</IF>
									<SWITCH match={currentView}>
										{allSteps}
									</SWITCH>
								</div>
							</div>

							{/* eslint-disable-next-line react/no-unknown-property */}
							<div case={VIEW_TYPES.SINGLE_FORM}>
								<IF condition={currentView !== BASE_VIEW_STATUSES.THANKS}>
									{allSteps
										.filter(({ key }) => (key !== BASE_VIEW_STATUSES.THANKS))
										.map((step) => (
											<div
												key={`step-${step.key}`}
												className="single-form-view-container"
											>
												{step}
											</div>
										)) }
								</IF>
								<IF condition={currentView === BASE_VIEW_STATUSES.THANKS}>
									{thanksStep}
								</IF>
							</div>

							{/* eslint-disable-next-line react/no-unknown-property */}
							<div case={VIEW_TYPES.OFFER_ONLY}>
								<IF condition={offerOnlyViews.length > 0}>
									<SWITCH match={currentView} test>
										{offerOnlySteps}
									</SWITCH>
								</IF>
								<IF condition={offerOnlyViews.length <= 0}>
									{offerOnly}
								</IF>
							</div>
						</SWITCH>
					</CallProvider>
				</Card>
			</div>
		</div>
	);
};

export default InnerForm;
